import * as React from "react"
import Seo from "../components/common/seo.js"
import Layout from "../components/common/layout.js"
import SoftwareSec from "../components/training-webinars/training-webinars.js"
import Hero from "../components/events/awards-hero-2024.js"
import SponsorsEN from "../images/awards/Sponsors-EN.png"
import SponsorsFR from "../images/awards/Sponsors-FR.png"

import AwardsImg14 from "../images/awards/awards-14.png"
import AwardsImg26 from "../images/awards/26-awards.png"
import AwardsImg30 from "../images/awards/30-awards.png"
import Guidelines from "../images/awards/Guidelines.png"
import mademoiselleSecBG from "../images/awards/mademoiselle-bg.jpg"
import mademoiselleLogo from "../images/awards/mademoiselle.png"
import judgesTitle from "../images/awards/judges.png"


import GoldCOMMBIcon from "../images/awards/gold-anchor.svg"
import { Player, ControlBar } from 'video-react';
import AwardsModal from "../components/awards/modal.js"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

import Luis from "../images/awards/judges/luis.png"
import Nikki from "../images/awards/judges/nikki.png"
import David from "../images/awards/judges/david.png"
import Michele from "../images/awards/judges/michele.png"
import Kyla from "../images/awards/judges/kyla.png"
import Justin from "../images/awards/judges/justin.png"
import Christina from "../images/awards/judges/christina.png"
import Ajay from "../images/awards/judges/ajay.png"
import LiAnn from "../images/awards/judges/li-ann.png"
import Mark from "../images/awards/judges/mark.png"
import Andrea from "../images/awards/judges/andrea.png"

import awardsTitle from "../images/awards/awards-title.png"
import theBestTitle from "../images/awards/the-best-title.png"
import aboutBG from "../images/awards/aboutBG.jpg"

import topDesignLine from "../images/awards/top-new-awards-line.png"




const Awards = () => (
  <div className="eventsPage membersZone awards">
    <Helmet>
    </Helmet>
    <Layout>
      <Seo title=" Awards | COMMB" />
      <div className="awards contactSecWrapper">
        <Hero />
        <div className="en-only desktopOnly"
              style={{
                position: 'relative', 
                width: '100%', 
                height: 0, 
                paddingTop: '50.0000%', 
                paddingBottom: 0, 
                boxShadow: '0 2px 8px 0 rgba(63, 69, 81, 0.16)', 
                overflow: 'hidden', 
                borderRadius: '8px', 
                willChange: 'transform'
              }}
            >
              <iframe 
                loading="lazy" 
                style={{
                  position: 'absolute', 
                  width: '100%', 
                  height: '100%', 
                  top: 0, 
                  left: 0, 
                  border: 'none', 
                  padding: 0,
                  margin: 0
                }}
                src="https://www.canva.com/design/DAGCCB2QRiY/RPTwxjOqOwlnxHN9Vt6AJQ/view?embed" 
                allowFullScreen
                allow="fullscreen"
              />
          </div>
          <div className="fr-only desktopOnly"
              style={{
                position: 'relative', 
                width: '100%', 
                height: 0, 
                paddingTop: '50.0000%', 
                paddingBottom: 0, 
                boxShadow: '0 2px 8px 0 rgba(63, 69, 81, 0.16)', 
                overflow: 'hidden', 
                borderRadius: '8px', 
                willChange: 'transform'
              }}
            >
              <iframe 
                loading="lazy" 
                style={{
                  position: 'absolute', 
                  width: '100%', 
                  height: '100%', 
                  top: 0, 
                  left: 0, 
                  border: 'none', 
                  padding: 0,
                  margin: 0
                }}
                src="https://www.canva.com/design/DAGCO55tKnQ/JasiT8sDjW1JJecBgtViuQ/view?embed" 
                allowFullScreen
                allow="fullscreen"
              />
        </div>
        <div 
          className="en-only mobileOnly"
            style={{
              position: 'relative', 
              width: '100%', 
              height: 0, 
              paddingTop: '111.1111%', 
              paddingBottom: 0, 
              boxShadow: '0 2px 8px 0 rgba(63, 69, 81, 0.16)', 
              overflow: 'hidden', 
              borderRadius: '8px', 
              willChange: 'transform'
            }}
          >
            <iframe 
              loading="lazy" 
              style={{
                position: 'absolute', 
                width: '100%', 
                height: '100%', 
                top: 0, 
                left: 0, 
                border: 'none', 
                padding: 0, 
                margin: 0
              }}
              src="https://www.canva.com/design/DAGCOxvE4mg/qAC639z1ONBEf8GGIgxEhA/view?embed" 
              allowFullScreen
              allow="fullscreen"
            />
        </div>
        <div 
          className="fr-only mobile-only"
  style={{
    position: 'relative', 
    width: '100%', 
    height: 0, 
    paddingTop: '111.1111%', 
    paddingBottom: 0, 
    boxShadow: '0 2px 8px 0 rgba(63, 69, 81, 0.16)', 
    overflow: 'hidden', 
    borderRadius: '8px', 
    willChange: 'transform'
  }}
>
  <iframe 
    loading="lazy" 
    style={{
      position: 'absolute', 
      width: '100%', 
      height: '100%', 
      top: 0, 
      left: 0, 
      border: 'none', 
      padding: 0, 
      margin: 0
    }}
    src="https://www.canva.com/design/DAGCO7xw044/YbZr_AtKKy2RpGJyJN5IWw/view?embed" 
    allowFullScreen
    allow="fullscreen"
  />
        </div>
        <section className="timelineInfo">
          <img src={topDesignLine} className="topCOMMBLine"/>
          <div className="container" style={{overflow: 'visible'}}>
            <h2>
            COMMB invites you to the second annual Canadian OOH Awards
            </h2>
            <div className="innerWrapper">
              <div>
                <img src={AwardsImg14} />
                <h4>Announcement</h4>
                <p>DEC 14, 2023</p>
              </div>
              <img src={topDesignLine} className="topCOMMBLine bottomCOMMBLine mobileOnly"/>
              <div>
                <img src={AwardsImg26 } />
                <h4>SUBMISSION DEADLINE</h4>
                <p>APR 26, 2024</p>
              </div>
              <img src={topDesignLine} className="topCOMMBLine bottomCOMMBLine mobileOnly"/>
              <div>
                <img src={AwardsImg30 } />
                <h4>AWARDS GALA</h4>
                <p>MAY 30, 2024</p>
              </div>
            </div>
            <img src={topDesignLine} className="topCOMMBLine bottomCOMMBLine"/>
            <h2 className="categoriesTitle">
              Check out our categories and
            </h2>
          </div>
          <img src={Guidelines} className="guidelinesImg" />
          <a href="https://downloads.ctfassets.net/1l6si2vnlb2k/3bv42LjzWanCsv5gxFxrgk/34ab0dd90de6c3c4186d6ed03d995c7e/Canadian_OOH_Awards_2024_-_Categories___Guidelines.pdf" target="_blank" class="btn">View Document </a>
        </section>
        <section className="mademoiselleSec">
          <h4>
          see you at <a href="https://g.co/kgs/ESSDHD2" target="_blank">Mademoiselle</a> on may 30
          </h4>
          <img src={mademoiselleLogo} />
          <p>
            Doors open at 5:30PM. 563 King St W, Toronto, Ontario, M5V 1M1
          </p>
        </section>
        <section className="darkBG eventScheduleSec">
          <div className="container" style={{ display: 'flex' }}>
            <div style={{ flex: '0.75' }}>
              <h2 className="title">
                <hr />
                Event Schedule
              </h2>
              <div className="boxEventsHighlight">
                Thursday<br />
                <span>May 30</span><br /> 
                <small>5:30 PM - 9:30 PM</small>
              </div>
            </div>
            <div className="registrationCards">
              <span>
                <h3>REGISTRATION | COCKTAIL & HORS D'OEUVRES
                  <small>5:30 PM - 7 PM</small>
                </h3>
              </span>
              <div className="whiteBGSec">
                <h2>Award Ceremony
                  <small>7 PM - 8 PM</small>
                </h2>
                <div>
                  <h3>CREATIVE CATEGORY AWARDS
                      <small>&nbsp;</small>
                    </h3>
                    <h3>STRATEGY CATEGORY AWARDS
                    <small>&nbsp;</small>
                    </h3>
                </div>
              </div>
              <span className="lastItem" style={{marginTop: '0.5rem'}}>
                <h3>INDUSTRY SOCIAL | COCKTAILS & HORS <br /> D'OEUVRES | EXPERIENTIAL ACTIVATION
                  <small>8 PM - 9:30 PM</small>
                </h3>
              </span>
              </div>
          </div>
        </section>
        <section className="judges">
          <div className="container">
            <h2>
              and thanks to our
            </h2>
            <img src={judgesTitle} className="judgesImg" />
            <div className="judgesCards">
              <div>
                <img src={Luis} />
                <h5>Luis Areas</h5>
                <p>Cartier</p>
              </div>
              <div>
                <img src={Nikki} />
                <h5>NIKKI stone</h5>
                <p>Wavemaker</p>
              </div>
              <div>
                <img src={David} />
                <h5>DAVID ALLARD</h5>
                <p>CAMPARI</p>
              </div>
              <div>
                <img src={Michele} />
                <h5>MICHELE BEAULIEU</h5>
                <p>SUBWAY</p>
              </div>
              <div>
                <img src={Kyla} />
                <h5>KYLA FRANCIS</h5>
                <p>APEX</p>
              </div>
              <div>
                <img src={Justin} />
                <h5>Justin senior</h5>
                <p>POCAM</p>
              </div>
              <div>
                <img src={Christina} />
                <h5>Christina Roche</h5>
                <p>ZULU ALPHA KILO</p>
              </div>
              
              <div>
                <img src={Ajay} />
                <h5>AJAY DURANI</h5>
                <p>DPAA</p>
              </div>
              <div>
                <img src={LiAnn} />
                <h5>Li-Ann D'Souza</h5>
                <p>Air Canada</p>
              </div>
              <div>
                <img src={Mark} />
                <h5>MARK FLYs</h5>
                <p>WOO</p>
              </div>
              <div>
                <img src={Andrea} />
                <h5>ANDREA POR</h5>
                <p>ZULU ALPHA KILO</p>
              </div>
            </div>
          </div>
        </section>
        <section className="aboutTheAwards">
          <div className="container">
            <h2>About The</h2>
            <img src={awardsTitle} className="awardsTitleImg" />
            <p>
              Launched in Spring 2023, the awards gala is an all-encompassing industry celebration recognizing  the creative, technical,
              and strategic achievements of agencies and  advertisers for their out-of-home campaigns. Winning categories are  focused on
              innovation, data, use of programmatic/digital technology, audience targeting, 360 planning, and more.
            </p>
            <a href="https://canadianooh.awardsplatform.com/" className="btn" target="_blank">
            SUBMIT YOUR CAMPAIGN BEFORE APRIL 26
            </a>
            <p className="buttonSummaryText">
            There is no limit to the number of categories a single entry may be entered as long as it fits into the individual category submission guidelines. 
            </p>
            <img src={theBestTitle} className="bestTitleImg"/>
            <p>
              From outdoor to place-based media; from painted murals to 3D digital spectaculars and everything in between, The Canadian Out-of-Home Awards is celebrating success from every corner of our industry.
            </p>
            <p className="last">
              The  out-of-home (OOH) industry in Canada is a rapidly growing channel in  the marketing and advertising sector.  OOH consists of media that  reaches people outside of their homes, such as billboards, posters,  digital signage, transit stop and vehicle ads, audio-visual displays,  experiential activations, and more.  A powerful way to reach large,  diverse audiences and create lasting impressions, OOH’s capabilities are  truly unmatched by any other medium.
            </p>
            <a href="/award-winners" className="btnOutline">
              <span>Click Here for the Winners’ Gallery of 2023 AWARDS</span>
            </a>
            <h2 className="lastTitle">For 2024 partnership inquiries, please contact <a href="ecrisante@commb.ca">Elizabeth Crisante</a> or <a href="awards@commb.ca">awards@commb.ca</a></h2>
          </div>
        </section>
        </div>
    </Layout>
  </div>
)

export default Awards