import React from "react"
import { Link } from "gatsby"

const Hero = () => {
    return (
        <div className="awards-Hero">
            <div className="container">
                <p className="mobileOnly">
                    The Canadian Out-of-Home Marketing & Measurement Bureau (COMMB) is pleased to present the annual Canadian Out-of-Home Awards in collaboration with their OOH operator members. 
                </p>
                <img src="https://images.ctfassets.net/1l6si2vnlb2k/kgFLbVhWQcWe8hoSmGhtE/cc34104757c7ff7b84e3429110bdcef6/awards-2024-logo.png" style={{ paddingTop: '16rem' }} className="heroAwardsLogo2024"/>
                <a href="https://assets.ctfassets.net/1l6si2vnlb2k/6ZTynxZpsxQXWjC0lq0l5i/4f464db49a9de0a7d71c2e37f37d7e98/Canadian_Out-of-Home_Award-s_Winner-s___Runners-up__2024_.docx" className="btn" target="_blank">
                VIEW ALL WINNERS & RUNNER’S UP
                </a>
                <p className="buttonSummaryText">
                There is no limit to the number of categories a single entry may be entered as long as it fits into the individual category submission guidelines. 
                </p>
            </div>
        </div>
    )
}

export default Hero